import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"

import "./latofonts.css"
import "./layout.scss"

const Layout = ({ children,pageclass }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data =>{ 
      
      return (
      
      <>
        <Header siteTitle={data.site.siteMetadata.title} goback={(pageclass)?true:false}/>
        
          <main className={pageclass}>{children}</main>
          <footer>
            © {new Date().getFullYear()} UNSTATIC INACTION &middot; <a href='mailto:krawieckrzysztof@gmail.com'>CONTACT</a>
          </footer>

      </>
    )}}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
