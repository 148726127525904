import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import "./header.scss"
import logo from "./../images/logosimple.svg"

import cross from "./../images/crossround.svg"
import scrollTo from 'gatsby-plugin-smoothscroll'


const Header = ({ siteTitle,goback }) => {
  
  //let xx = 'asdasd'
  return (
  <header className='black'>

        <AniLink cover bg="#ffffff" to="/">
          <img className='logo' src={logo} alt = 'logo - ux design & development'/>

        </AniLink>

        <a onClick={() => scrollTo('#contact')} className='scrolltocontact' style={{visibility: goback ? 'hidden' : 'visible' }}>CONTACT ↘</a>
        <a onClick={() => scrollTo('#casestudieslist')} className='scrolltolist' style={{visibility: goback ? 'visible' : 'hidden' }}><span>FULL </span>CS LIST ↘</a>
        <AniLink cover bg="#000000" className='close' to="/">
        <img className='close' src={cross} alt = 'goback' style={{visibility: goback ? 'visible' : 'hidden' }}/>
        </AniLink>

  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
